import React, { useRef, useState } from 'react';
import './App.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { html } from 'common-tags';
import { message } from 'statuses';

firebase.initializeApp({
  apiKey: "AIzaSyArUZnVbtBBnIJL7Mh2AAhaj21-7TIicqE",
  authDomain: "cool-kid-chat-app.firebaseapp.com",
  projectId: "cool-kid-chat-app",
  storageBucket: "cool-kid-chat-app.appspot.com",
  messagingSenderId: "201991791099",
  appId: "1:201991791099:web:1bcfd9c0d06d7b0407ab5e",
  measurementId: "G-2WYW6XDT04"
})

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header><h1>Keylen's Chat!</h1>
        <SignOut />
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
    const user = auth
  }

  return (
    <>
      <button className="sign-in googleBtn" onClick={signInWithGoogle}>Sign in with Google</button>
      <p class="centeredParagraph">This is Kellen's cool chat room. Talk to friends, have a fun time, and share links on this awesome website!</p>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt');

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL, displayName } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    <form onSubmit={sendMessage}>

      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="talk to your friends" />

      <button type="submit" class="submit" disabled={!formValue}>📫</button>

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL, displayName } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  if (uid == "aYB9pXv3nmame2XRlafJvWVNWid2") {
    return (<>
      <div className={`message ${messageClass}`}>
        <p id="usrName">{displayName} ✔</p>
        <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
        <p>{text}</p>
      </div>
    </>)
  }
  else {
    return (<>
      <div className={`message ${messageClass}`}>
        <p id="usrName">{displayName}</p>
        <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
        <p>{text}</p>
      </div>
    </>)
  }

}


export default App;
